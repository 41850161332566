import { motion } from 'framer-motion';
import { ScreenCalibration } from './components/ScreenCalibration';
import { SetupProvider } from './context/SetupContext';

const variants = {
  hidden: { opacity: 0, scale: 1.1 },
  enter: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.9 },
};

export default function App(props) {
  return (
    <SetupProvider>
      <motion.section
        variants={variants}
        initial="hidden"
        animate="enter"
        exit="exit"
        className="fixed left-0 top-0 z-10 flex h-[100dvh] w-[100dvw] items-center justify-center bg-gradient-to-br from-[#FFFFFF] to-[#E5EEFF] px-4">
        <ScreenCalibration />
      </motion.section>
    </SetupProvider>
  );
}
