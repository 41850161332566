import { createContext, useContext } from 'react';
import * as singleSpa from 'single-spa';

type TSetupContext = {
  dismissSetup: () => void;
};

const SetupContext = createContext<TSetupContext>({} as TSetupContext);

export const useSetupContext = () => {
  const context = useContext(SetupContext);

  if (!context) {
    throw new Error('useSetupContext must be used within a SetupProvider');
  }

  return context;
};

export function SetupProvider({ children }: { children: React.ReactNode }) {
  function dismissSetup() {
    singleSpa.unregisterApplication('@bv/employee-setup');
  }

  return <SetupContext.Provider value={{ dismissSetup }}>{children}</SetupContext.Provider>;
}
