import { useEffect, useState } from 'react';
import { InstructionsModal } from './components/InstructionsModal';
import { HelpCircleIcon, PlusIcon } from 'lucide-react';
import { IconButton, Slider } from '@eyecarehealth/ds-aquilae-react';
import { placeholderCartaoAsset, placeholderCartaoAsset2 } from '@employee-setup/assets';
import { CardCalibrator } from './components/CardCalibrator';

export function ScreenCalibration() {
  const [modalInstructions, setModalInstructions] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setModalInstructions(true);
    }, 1000);
  }, []);

  return (
    <section className="flex h-full w-full flex-col items-center justify-center">
      <div className="flex h-40 flex-col items-center justify-center gap-2 md:flex-row">
        <h1 className="text-typography text-center text-xl font-bold">
          Essa etapa garante que os testes visuais sejam precisos e adaptados às suas necessidades.
          <button className="mx-auto block md:hidden" onClick={() => setModalInstructions(true)}>
            <span className="text-brand-primary underline">Ajuda</span>
          </button>
        </h1>
        <IconButton className="hidden md:flex" variant="default" onClick={() => setModalInstructions(true)}>
          <HelpCircleIcon className="text-brand-primary" size={20} />
        </IconButton>
      </div>
      <CardCalibrator />

      <InstructionsModal open={modalInstructions} setOpen={setModalInstructions} />
    </section>
  );
}
