import { useState } from 'react';
import { placeholderCartaoAsset2 } from '@employee-setup/assets';
import { PlusIcon } from 'lucide-react';
import { Button, cn, Slider } from '@eyecarehealth/ds-aquilae-react';
import bvUtils from '@bv/utils';
import { CREDIT_CARD_WIDTH_CM } from '@employee-setup/constants/screenCalibration';
import { useSetupContext } from '@employee-setup/context/SetupContext';

export function CardCalibrator() {
  const [calibrationWidthPx, setCalibrationWidthPx] = useState(350);
  const { dismissSetup } = useSetupContext();

  function saveScreenCalibration() {
    // Calcula o px/cm e salva no estado e no localStorage
    const newPxPerCm = calibrationWidthPx / CREDIT_CARD_WIDTH_CM;
    bvUtils.screen.saveScreenCalibration(newPxPerCm);

    console.log(`Calibração salva!\nPixels por centímetro = ${newPxPerCm.toFixed(2)} (px/cm)`);
    dismissSetup();
  }

  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-8">
      <div className="flex flex-1 items-center gap-2">
        <div
          className="relative inline-block h-auto w-auto"
          style={{ right: bvUtils.device.IS_MOBILE ? `-${60 - calibrationWidthPx / 100}%` : 'unset' }}>
          <div className="relative">
            <Crosshair className="absolute -left-0 -top-0" />
            <Crosshair className="absolute -right-0 -top-0" />
            <Crosshair className="absolute -bottom-0 -left-0" />
            <Crosshair className="absolute -bottom-0 -right-0" />
            <img
              className={`h-[auto] max-w-[unset]`}
              style={{ width: `${calibrationWidthPx}px` }}
              src={placeholderCartaoAsset2}
              alt="placeholder cartão"
            />
          </div>
        </div>
      </div>
      <div className="mx-auto flex h-28 w-[400px] max-w-full flex-col items-center gap-4 max-sm:px-8">
        <Slider
          className="w-full"
          defaultValue={[350]}
          max={600}
          step={1}
          value={[calibrationWidthPx]}
          onValueChange={(value) => setCalibrationWidthPx(value[0])}
        />
        {/* <input
          type="range"
          value={calibrationWidthPx}
          className="w-full"
          step={1}
          min={300}
          max={600}
          onChange={(e) => setCalibrationWidthPx(Number(e.target.value))}
        /> */}
        <Button size="lg" onClick={saveScreenCalibration}>
          Salvar calibragem
        </Button>
      </div>
    </div>
  );
}

function Crosshair({ className }: { className?: string }) {
  return (
    <div className={cn('flex items-center justify-center', className)}>
      <div className="absolute h-[2px] w-7 bg-[#A8B2C7]" />
      <div className="absolute h-[2px] w-7 rotate-90 bg-[#A8B2C7]" />
    </div>
  );
}
