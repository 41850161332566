import bvUtils from '@bv/utils';
import {
  screenCalibrationTutorialAnimation1,
  screenCalibrationTutorialAnimation2,
  screenCalibrationTutorialAnimation3,
} from '@employee-setup/assets';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  DialogClose,
  DrawerTitle,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerDescription,
  DrawerFooter,
  DrawerClose,
} from '@eyecarehealth/ds-aquilae-react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@eyecarehealth/ds-aquilae-react';
import Lottie from 'lottie-react';

type InstructionsModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function InstructionsModal({ open, setOpen }: InstructionsModalProps) {
  if (bvUtils.device.IS_MOBILE)
    return (
      <Drawer open={open} onOpenChange={setOpen}>
        <DrawerContent className="sm:max-w-[600px]">
          <DrawerHeader>
            <DrawerTitle className="text-xl leading-6">Antes de começar os testes, vamos calibrar sua tela.</DrawerTitle>
            <DrawerDescription>Essa etapa garante que os testes visuais sejam precisos e adaptados às suas necessidades.</DrawerDescription>
          </DrawerHeader>
          <div className="p-6 pb-0">
            <Alert variant="info">
              <AlertDescription>
                <ul className="list-decimal space-y-2 px-2">
                  <li>
                    <strong>Pegue um cartão físico</strong> (como um cartão de crédito ou débito).
                  </li>
                  <li>
                    <strong>Posicione-o sobre a imagem exibida na tela.</strong>
                  </li>
                  <li>
                    <strong>Use o controle deslizante abaixo para ajustar o tamanho da imagem na tela</strong> até que ela corresponda ao tamanho do
                    cartão físico.
                  </li>
                </ul>
              </AlertDescription>
            </Alert>
            <div className="relative mt-2 flex h-[145px] items-center justify-center overflow-hidden">
              <Lottie
                animationData={screenCalibrationTutorialAnimation3}
                loop={true}
                className="absolute -top-[42px] h-[300px] w-[300px] translate-x-5"
              />
            </div>
          </div>
          <DrawerFooter>
            <DrawerClose>
              <Button type="button" variant="ghost">
                Fechar
              </Button>
            </DrawerClose>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle className="leading-6">
            Antes de começar os testes, vamos calibrar sua tela.
            <br /> É rápido e nós te ajudamos!
          </DialogTitle>
          <DialogDescription>Essa etapa garante que os testes visuais sejam precisos e adaptados às suas necessidades.</DialogDescription>
        </DialogHeader>
        <div className="py-4">
          <Alert variant="info">
            <AlertDescription>
              <ul className="list-decimal space-y-2 px-2">
                <li>
                  <strong>Pegue um cartão físico</strong> (como um cartão de crédito ou débito).
                </li>
                <li>
                  <strong>Posicione-o sobre a imagem exibida na tela.</strong>
                </li>
                <li>
                  <strong>Use o controle deslizante abaixo para ajustar o tamanho da imagem na tela</strong> até que ela corresponda ao tamanho do
                  cartão físico.
                </li>
              </ul>
            </AlertDescription>
          </Alert>
          <div className="relative flex h-[250px] items-center justify-center">
            <Lottie animationData={screenCalibrationTutorialAnimation1} loop={true} className="absolute h-[400px] w-[400px]" />
            {/* <Lottie animationData={screenCalibrationTutorialAnimation2} loop={true} className="h-[400px] w-[400px]" /> */}
            {/* <Lottie animationData={screenCalibrationTutorialAnimation3} loop={true} className="h-[400px] w-[400px]" /> */}
          </div>
        </div>
        <DialogFooter>
          <DialogClose>
            <Button type="button" variant="ghost">
              Fechar
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
